<!-- eslint-disable vue/no-parsing-error -->
<template>
  <section>
    <b-modal
      v-model="showModal"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      title="External Ad Checkout"
      class="p-4"
      @hide="hide"
    >
      <div
        id="checkout"
        class="m-2"
      >
        <b-row class="pricing-card">
          <b-col
            offset-sm-2
            sm="10"
            md="12"
            offset-lg="2"
            lg="10"
            class="mx-auto"
          >
            <b-row>
              <b-col
                md="12"
                sm="12"
                lg="12"
              >
                <!-- title text and switch button -->
                <div class="text-center">
                  <h3 class="font-weight-bolder mb-2">
                    Payment
                  </h3>
                  <span class="my-4 font-weight-bolder text-primary font-large-1">
                    {{ postData.name ? postData.name : 'package-name' }}
                  </span>
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup
                        class="font-medium-1 font-weight-bolder"
                        style="color:black;"
                      >$</sup>
                      <span
                        class="pricing-basic-value font-weight-bolder text-primary"
                      >{{ postData.amount? postData.amount : 0 }}</span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/only</sub>
                    </div>
                  </div>
                </div>
                <div
                  v-if="stripe_customer_card.length"
                  class="ml-1 mb-2"
                >
                  <!-- <b-form-checkbox
                    v-model="useExistingCard"
                    name="checkbox-1"
                  >
                    Use existing card
                  </b-form-checkbox> -->
                  <!-- v-if="useExistingCard" -->
                  <div>
                    <div
                      class="font-weight-bolder py-2"
                    >
                      Please select an existing card:
                    </div>
                    <b-form-group>
                      <b-form-radio-group
                        v-model="selectedCard"
                        name="selectedCard"
                        stacked
                        @change="onCardSelect"
                      >
                        <b-form-radio
                          v-for="(card) in stripe_customer_card"
                          :key="card.id"
                          :value="card"
                          style="margin-bottom: 10px;"
                        >
                          XXXX-XXXX-XXXX-{{ card.card.last4 }} - <img
                            :src="getSmallCardBrandIconUrl(card.card.display_brand)"
                            alt=""
                            width="40px"
                            height="20px"
                          >
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                </div>

                <!-- <div v-if="!useExistingCard">
                  <b-form @submit.prevent>
                    <b-col>
                      <b-form-group>
                        <label
                          class="font-weight-bold"
                        >Card number</label>
                        <b-input-group class="input-group-merge">
                          <div
                            id="card-number"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-row class="ml-0 mr-0">
                      <b-col md="6">
                        <b-form-group>
                          <label
                            for="card-expiry"
                            class="font-weight-bold"
                          >Validity</label>
                          <b-input-group class="input-group-merge">
                            <div id="card-expiry" />
                            <div class="card-icon">
                              <img
                                src="@/assets/images/Expiry.png"
                                class="cursor-pointer"
                                alt="expiry-icon"
                              >
                            </div>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label
                            for="card-cvc"
                            class="font-weight-bold"
                          >CVC</label>
                          <b-input-group class="input-group-merge">
                            <div id="card-cvc" />
                            <div class="card-icon">
                              <img
                                src="@/assets/images/CVC.png"
                                class="cursor-pointer"
                                alt="cvc-icon"
                              >
                            </div>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <div
                          id="card-error"
                          class="mt-1 mb-2"
                        />
                      </b-col>
                    </b-row>
                  </b-form>

                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      style="width: inherit !important;"
                      :disabled="lockSubmit"
                      @click="createToken"
                    >
                      <div
                        v-if="lockSubmit"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                        <span class="sr-only">Loading...</span>
                      </div>
                      Add Card and Pay
                    </b-button>
                  </b-col>
                </div> -->
                <!-- v-if="!useExistingCard" -->
                <div>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      style="width: inherit !important;"
                      :disabled="!selectedCard || lockSubmit"
                      @click="createPmpAd"
                    >
                      <div
                        v-if="lockSubmit"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                        <span class="sr-only">Loading...</span>
                      </div>
                      Pay
                    </b-button>
                  </b-col>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <!--/ pricing plan cards -->
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  // BInputGroup,
  // BForm,
  // BFormCheckbox,
  BSpinner,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BSpinner,
    // BInputGroup,
    // BForm,
    // BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  props: {
    postData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      hideForm: false,
      useExistingCard: false,
      token: null,
      //   packagePrice,
      cardHolder: null,
      cardNumber: null,
      cardExpiry: null,
      stripeElements: null,
      //   MemberQuota,
      cardCvc: null,
      lockSubmit: false,
      spinner: false,
      stripe_customer_card: [],
      selectedCard: null,
      name: '',
      selectPlan: '',
      planOptions: [],
      pmp_ads: null,
      // validation
      required,
      AccountTypes,
    }
  },
  computed: {
    getUserExistingCard() {
      const user = this.$store.getters['user/getUser']
      return user?.stripe_customer_card ? user.stripe_customer_card : null
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  watch: {
    useExistingCard(newValue) {
    //     setTimeout(() => {
    //     this.stripeElements = this.$stripe.elements()
    //     this.stripeComponentMountFunc()
    //   }, 1)
      if (newValue === false) {
        this.selectedCard = null
      }
    },
  },
  async mounted() {
    this.getCustomerCardDetails()
  },
  methods: {
    show() {
      this.showModal = !this.showModal
      this.pmp_ads = this.postData
    //   setTimeout(() => {
    //     this.stripeElements = this.$stripe.elements()
    //     this.stripeComponentMountFunc()
    //   }, 1)
    },
    stripeComponentMountFunc() {
      const style = {
        base: {
          // color: 'black',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
      this.cardNumber = this.stripeElements.create('cardNumber', { showIcon: true, iconStyle: 'default', style })
      this.cardNumber.mount('#card-number')
      this.cardExpiry = this.stripeElements.create('cardExpiry', { style })
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc = this.stripeElements.create('cardCvc', { style })
      this.cardCvc.mount('#card-cvc')
    },
    async createToken() {
      this.lockSubmit = true
      // eslint-disable-next-line no-unused-vars
      const { token, error } = await this.$stripe.createToken(this.cardNumber)
      if (error) {
        // handle error here
        document.getElementById('card-error').innerHTML = error.message
        this.$forceUpdate() // Forcing the DOM to update so the Stripe Element can update.
        this.lockSubmit = false
      }
      // return token.id for payment from here
    },
    onCardSelect(selected) {
      this.pmp_ads.paymentMethodId = selected.id
    },
    getSmallCardBrandIconUrl(img) {
      const images = require.context('@/assets/images/smallCardBrandIcons/', false, /\.png$/)
      return images(`./${img}.png`)
    },
    hide() {
      this.showModal = !this.showModal
      this.selectedCard = null
    },
    async createPmpAd() {
      this.lockSubmit = true
      this.spinner = true
      try {
        if (this.isUserAdmin) {
          await this.$store.dispatch('pmpAds/createNewPMPAdsByAdmin', { postData: this.pmp_ads, groupId: this.$route.params.userId })
          this.hide()
          this.$router.push(`/users/${this.$route.params.userId}/user-pmp-ads`)
        } else {
          await this.$store.dispatch('pmpAds/createNewPMPAdsByUser', { postData: this.pmp_ads })
          this.hide()
          this.$router.push('/pmp-ads')
        }
      } catch (error) {
        console.error('An error occurred:', error.response)
      } finally {
        this.spinner = false
        this.lockSubmit = false
      }
    },
    async getCustomerCardDetails() {
      try {
        if (!this.isUserAdmin) {
          const resp = await this.$axios.get('/stripe/cards')
          this.stripe_customer_card = resp.data.data
        } else {
          const resp = await this.$axios.get('/stripe/admin/get-user-card', {
            headers: {
              userHasGroupId: this.$route.params.userId,
            },
          })
          this.stripe_customer_card = resp.data.data
        }
      } catch (error) {
        showToast('Card Error', error.response.data.message ? error.response.data.message : error.message, 'danger', 4000)
      }
    },
  },
}
/* eslint-disable global-require */
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/page-pricing.scss';
  #checkout{
    .search {
      .vs__dropdown-menu {
        max-height: 80px !important;
        overflow-y: auto !important;
        width: 320px !important;
      }
      .vs__dropdown-toggle{
        max-height: 60px !important;
        overflow-y: auto !important;
        width: 320px !important;
      }
    }
    .btns-class{
      width: 100%;
      margin: -15px;
      margin-bottom: 15px;
    }
    #card-number,
    #card-expiry,
    #card-cvc,
    #subscription{
      background: white;
      padding: 12px;
      border: 1px solid #d0d2d6;
      border-radius: 7px;
      width: 100%;
      position: relative;
    }
    .card-icon{
      position: absolute;
      right: 3%;
      top: 25%;
    }
    #custom-button{
      margin: 10px 0;
      display: block;
    }
    #card-error {
      color: red;
    }
  }
  </style>
